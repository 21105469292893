import './App.css';
import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'

import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { currentUser, logoutUserWithoutConfirm } from './reducers/auth';
import axios from 'axios'
import { API_URL } from './Globals/constant'
import Loader from "./common/Spinner"
import { listSetting } from './reducers/setting';
import { Provider } from 'react-redux';
import store from './store';
import PrivateRoute from './common/PrivateRoute';
// import Notification from './Notification';
import ScrollToTop from './common/ScrollToTop';
import InvestmentRecieved from './pages/farmer/InvestmentRecieved';

import { getFeaturedListing } from './reducers/listing';
const Landing = React.lazy(() => import('./pages/home/Landing'));
const ChooseType = React.lazy(() => import('./components/authentication/partner/ChooseType'))
const Register = React.lazy(() => import('./components/authentication/partner/Register'))
const Login = React.lazy(() => import('./pages/authentication/Login'))
const GetStarted = React.lazy(() => import('./pages/authentication/GetStarted'))
const ChooseRole = React.lazy(() => import('./pages/authentication/ChooseRole'))

const AboutUS = React.lazy(() => import('./pages/home/AboutUs'))
const ListFarm = React.lazy(() => import('./pages/farms/ListFarm'))
const VerifyAccount = React.lazy(() => import('./components/authentication/VerifyAccount'))
const VerifyOTP = React.lazy(() => import('./components/authentication/VerifyOTP'))
const ForgotPassword = React.lazy(() => import('./components/authentication/ForgotPassword'))
const UpdatePassword = React.lazy(() => import('./components/authentication/UpdatePassword'))
const Membership = React.lazy(() => import('./pages/membership/Membership'))
const NoPlan = React.lazy(() => import('./pages/membership/NoPlan'))

const MyProfile = React.lazy(() => import('./pages/profile/MyProfile'))
const FAQ = React.lazy(() => import('./pages/home/FAQ'))
const KnowYourUser = React.lazy(() => import('./pages/home/KnowYourUserInvestor'))
const KnowYourFarmer = React.lazy(() => import('./pages/home/KnowYourFarmer'))
const KnowYourSupplyChain = React.lazy(() => import('./pages/home/KnowYourSupplyChain'))
const KnowYourPartner = React.lazy(() => import('./pages/home/KnowYourPartner'))
const TermsAndCondition = React.lazy(() => import('./pages/home/TermsAndConditions'))
const ListingTerms = React.lazy(() => import('./pages/farmer/ListingTerms'))
const PrivacyPolicy = React.lazy(() => import('./pages/home/PrivacyPolicy'))
const CookiePolicy = React.lazy(() => import('./pages/home/CookiePolicy'))
const Team = React.lazy(() => import('./pages/home/Team'))
const Contact = React.lazy(() => import('./pages/home/Contact'))
const ComingSoon = React.lazy(() => import('./pages/home/ComingSoon'))
const Certificate = React.lazy(() => import('./pages/home/Certificate'))


const MyPayement = React.lazy(() => import('./pages/payment/MyPayement'))

// Farmer Pages
const KYC = React.lazy(() => import('./pages/kyc/Kyc'))
const AddUserKyc = React.lazy(() => import('./pages/kyc/user/AddUserKyc'))
const AddTechnologyKyc = React.lazy(() => import('./pages/kyc/technology/AddTechnologyKyc'))
const AddVendorKyc = React.lazy(() => import('./pages/kyc/vendor/AddVendorKyc'))
const AddInvestCompanyKyc = React.lazy(() => import('./pages/kyc/investcompany/AddInvestCompanyKyc'))

const AddLand = React.lazy(() => import('./pages/farmer/AddLand'))
const ViewLand = React.lazy(() => import('./pages/farmer/ViewLand'))


const AddCrop = React.lazy(() => import('./pages/farmer/AddCrop'))
const ViewCrop = React.lazy(() => import('./pages/farmer/ViewCrop'))

const SPV = React.lazy(() => import('./pages/farms/SPV'))
const LeaseAgreement = React.lazy(() => import('./pages/farms/LeaseAgreement'))

const ChooseInvestment = React.lazy(() => import('./pages/farmer/ChooseInvestment'))
const FarmerDashboard = React.lazy(() => import('./pages/farmer/Dashboard'))
const MyListings = React.lazy(() => import('./pages/farmer/MyListings'))
const RequestList = React.lazy(() => import('./pages/farmer/RequestList'))

const SelectTechnologyPartner = React.lazy(() => import('./pages/farmer/SelectTechnologyPartner'))
const ChooseTechnologyPartner = React.lazy(() => import('./pages/farmer/ChooseTechnologyPartner'))
const MakeCropListing = React.lazy(() => import('./pages/farmer/MakeCropListing'))

const PaymentSuccess = React.lazy(() => import('./pages/payment/Success'));
const PaymentFailure = React.lazy(() => import('./pages/payment/Failure'));


const FarmDetail = React.lazy(() => import('./pages/farms/FarmDetail'))
const Checkout = React.lazy(() => import('./pages/farms/Checkout'))


// Partner Pages

const ROIRequest = React.lazy(() => import('./pages/partners/ROIRequest'))
const ROISubmit = React.lazy(() => import('./pages/partners/ROISubmit'))
const ROIDetail = React.lazy(() => import('./pages/partners/ROIDetail'))
const MyMembership = React.lazy(() => import('./pages/partners/MyMembership'))
const MyInvestment = React.lazy(() => import('./pages/investor/MyInvestment'))


const InvestmentCheckout = React.lazy(() => import('./pages/farmer/InvestmentCheckout'))



//ESCROE PAGES
const EscrowForm = React.lazy(() => import('./pages/escrow/EscrowForm'))
const ViewListings = React.lazy(() => import('./pages/escrow/ViewListings'))
const ViewFundRequests = React.lazy(() => import('./pages/escrow/ViewFundRequests'))

const ListingDetail = React.lazy(() => import('./pages/escrow/ListingDetail'))
const EditEscrowRequest = React.lazy(() => import('./pages/escrow/EditEscrowRequest'))
const RefundLists = React.lazy(() => import('./pages/escrow/RefundLists'))
const ViewRefundDetails = React.lazy(() => import('./pages/escrow/ViewRefundDetails'))
const ViewReleaseFund = React.lazy(() => import('./pages/escrow/ViewReleaseFund'))
const ViewReleaseFundDetails = React.lazy(() => import('./pages/escrow/ViewReleaseFundDetails'))
const AccountDeletion = React.lazy(() => import('./pages/home/AccountDeletion'))

axios.defaults.baseURL = API_URL;



store.dispatch(listSetting());
store.dispatch(getFeaturedListing(`?status=Active&featureAd=Yes&limit=8`))





if (localStorage.jwtToken) {
  //Set Auth Token header Auth
  setAuthToken(localStorage.jwtToken);
  //Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(currentUser(decoded));
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUserWithoutConfirm());
    //ToDO : clear current profile
    //Redirect to Login page
    window.location.href = '/';
  }

}


function App() {

  const onIdle = () => {
    if (localStorage.jwtToken) {
      store.dispatch(logoutUserWithoutConfirm());
      window.location.reload();
    }
  }



  const {
    getRemainingTime,
    getTabId,
    isLeader,
    isLastActiveTab,
    message
  } = useIdleTimer({
    onIdle,
    timeout: 900_000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  })

  return (
    <Provider store={store}>
      <Router
        exact
        path="/"
      >
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <Routes>

            <Route path="/" element={<Landing />} index exact />

            {/* USER AUTHTICATION ROUTES */}
            <Route path="/:roleType/register" exact element={<Register />} />
            <Route path="/choosetype" exact element={<ChooseType />} />

            <Route path="/verifyaccount" exact element={<VerifyAccount />} />
            <Route path="/verifyotp" exact element={<VerifyOTP />} />
            <Route path="/forgotpassword" exact element={<ForgotPassword />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/get-started" exact element={<GetStarted />} />
            <Route path="/choose-role" exact element={<ChooseRole />} />

            {/* USER AUTHTICATION ROUTES END*/}


            <Route path="/aboutus" exact element={<AboutUS />} />
            <Route path="faq" exact element={<FAQ />} />
            <Route path="/know-your-user-investor" exact element={<KnowYourUser />} />
            <Route path="/know-your-farmer" exact element={<KnowYourFarmer />} />
            <Route path="/know-your-supply-chain" exact element={<KnowYourSupplyChain />} />
            <Route path="/know-your-partner" exact element={<KnowYourPartner />} />
            <Route path="/terms-and-conditions" exact element={<TermsAndCondition />} />
            <Route path="/listing-terms-and-conditions" exact element={<ListingTerms />} />
            <Route path="/help/account-deletion" exact element={<AccountDeletion />} />


            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" exact element={<CookiePolicy />} />

            <Route path="/team" exact element={<Team />} />
            <Route path="/contact" exact element={<Contact />} />

            <Route path="/coming-soon" exact element={<ComingSoon />} />

            <Route path="/certificate" exact element={<Certificate />} />

            <Route path="/listfarms" exact element={<ListFarm title={"Farms"} />} />
            {/* <Route path="/listfarms" exact element={<ComingSoon />} /> */}

            <Route path="/listcrops" exact element={<ListFarm title={"Crops"} />} />
            <Route path="/listall" exact element={<ListFarm title={"View All"} />} />


            <Route path="/farmdetail/:landid" exact element={<FarmDetail />} />
            <Route path="/checkout/" exact element={<Checkout />} />





            {/* //FARMER ROUTES */}




            <Route path="/" element={<PrivateRoute />}>
              <Route path="/dashboard" exact element={<FarmerDashboard />} />
              <Route path="/membership" exact element={<Membership />} />
              <Route path="/noplan" exact element={<NoPlan />} />
              <Route path="/my-payement" exact element={<MyPayement />} />
              <Route path="/my-profile" exact element={<MyProfile />} />
              <Route path="/user/updatepassword" exact element={<UpdatePassword />} />

              <Route path="/kycstatus" exact element={<KYC />} />
              <Route path="/farmer/addkyc" exact element={<AddUserKyc />} />
              <Route path="/investor/addkyc" exact element={<AddUserKyc />} />
              <Route path="/technology/addkyc" exact element={<AddTechnologyKyc />} />
              <Route path="/buyvendor/addkyc" exact element={<AddVendorKyc />} />
              <Route path="/sellvendor/addkyc" exact element={<AddVendorKyc />} />
              <Route path="/investcompany/addkyc" exact element={<AddInvestCompanyKyc />} />


              <Route path="/farmer/chooseinvestment" exact element={<ChooseInvestment />} />
              <Route path="/farmer/choosetechnologypartner" element={<ChooseTechnologyPartner />} />

              <Route path="/farmer/addland" exact element={<AddLand />} />

              <Route path="/farmer/viewland/:landid" exact element={<ViewLand />} />



              <Route path="/farmer/addcrops" exact element={<AddCrop />} />
              <Route path="/farmer/makecroplisting/:landid" element={<MakeCropListing />} />
              <Route path="/farmer/viewcrop/:landid" exact element={<ViewCrop />} />

              <Route path="/farmer/mylistings" exact element={<MyListings />} />
              <Route path="/farmer/technologypartner/:landid" exact element={<SelectTechnologyPartner />} />

              <Route path="/farmer/requestlist" exact element={<RequestList />} />
              <Route path="/farmer/viewrequest/:requestid" exact element={<ROIDetail />} />

              <Route path="/farmer/investmentrecieved/:landid" exact element={<InvestmentRecieved />} />
              <Route path="/farmer/investmentcheckout/:landid" exact element={<InvestmentCheckout />} />

              <Route path="/spv/:landid" exact element={<SPV />} />
              <Route path="/leaseagreement/:landid" exact element={<LeaseAgreement />} />


              {/* //Invester ROUTES */}
              <Route path="/:userrole/myinvestment" exact element={<MyInvestment />} />

              {/* {PARTNER ROUTES} */}
              <Route path="/partner/requestlist" exact element={<RequestList />} />
              <Route path="/partner/viewrequest/:requestid" exact element={<ROIDetail />} />
              <Route path="/partner/my-membership" exact element={<MyMembership />} />
              <Route path="/partner/mylistings" exact element={<MyListings />} />
              <Route path="/partner/investmentrecieved/:landid" exact element={<InvestmentRecieved />} />



              {/* {PAYMENT GETEWAY ROUTES} */}
              <Route exact path="/payment/success" element={<PaymentSuccess />} />
              <Route exact path="/payment/failure" element={<PaymentFailure />} />

              <Route path="/farmer/request-fund/:landid" exact element={<EscrowForm />} />



              {/* //ESCROW ROUTES */}
              <Route exact path="/escrow/view-listings" element={<ViewListings />} />
              <Route exact path="/escrow/fundrequests" element={<ViewFundRequests />} />

              <Route path="/escrow/fundreleases" exact element={<ViewReleaseFund />} />
              <Route path="/escrow/edit-release-fund/:landid" exact element={<ViewReleaseFundDetails />} />

              <Route path="/escrow/listing-detail/:landid" exact element={<ListingDetail />} />
              <Route path="/escrow/edit-request/:requestid" exact element={<EditEscrowRequest />} />
              <Route path="/escrow/refundlist" exact element={<RefundLists />} />
              <Route path="/escrow/edit-refund/:landid" exact element={<ViewRefundDetails />} />




            </Route>
            {/* <Route element={<Error />} /> */}



          </Routes>
        </Suspense>
        {/* <Notification /> */}
      </Router>

    </Provider>
  )

}

export default App;
